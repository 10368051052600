import { Theme } from '@emotion/react';

declare module '@emotion/react' {
  export interface Theme {
    colors: {
      primary100: string;
      primary300: string;
      primary400: string;
      primary500: string;
      primary600: string;
      primary700: string;
      primary800: string;
      primary900: string;

      primary950: string;

      secondary500: string;
      secondary600: string;
    };
    sidebarWidth: string;

    profileWidth: number;
    profileBackgroundColor: string;
  }
}

export const theme: Theme = {
  sidebarWidth: '15rem',

  profileWidth: 25 * 16,
  profileBackgroundColor: '#2d2d2d77',

  colors: {
    primary100: '#FFF',
    primary300: '#919198',
    primary400: '#73738c',
    primary500: '#626268',
    primary600: '#505058',
    primary700: '#0050D4',
    primary800: '#21212b',
    primary900: '#40478d',
    primary950: '#2d2d2d',
    secondary500: '#0050D4',
    secondary600: '#305f88',
  },
};
