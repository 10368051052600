import styled from '@emotion/styled';
import SCModalFrame from '../SCModalFrame';
import SCButton from '../SCButton';

export const ModalFrame = styled(SCModalFrame)`
  min-width: 20rem;
  min-height: 20rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  color: ${props => props.theme.colors.primary100};

  margin-bottom: 1rem;
`;

export const Description = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.colors.primary100};

  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;

  margin-top: 1rem;

  justify-content: center;
`;

export const Button = styled(SCButton)`
  font-size: 1rem;
`;
