import soundButton1 from '../assets/sounds/botoes_genericos_01.mp3';
import soundButton2 from '../assets/sounds/botoes_genericos_02.mp3';
import soundButton3 from '../assets/sounds/botoes_genericos_03.mp3';
import soundButton4 from '../assets/sounds/botoes_genericos_04.mp3';
import soundButton5 from '../assets/sounds/botoes_genericos_05.mp3';
import soundButton6 from '../assets/sounds/botoes_genericos_06.mp3';
import buttonSidebar01 from '../assets/sounds/botoes_laterais_01.mp3';
import buttonSidebar02 from '../assets/sounds/botoes_laterais_02.mp3';
import buttonSidebar03 from '../assets/sounds/botoes_laterais_03.mp3';
import buttonSidebar04 from '../assets/sounds/botoes_laterais_04.mp3';
import sound1 from '../assets/sounds/popup_pergaminhos_01.mp3';
import sound2 from '../assets/sounds/popup_pergaminhos_02.mp3';
import sound3 from '../assets/sounds/popup_pergaminhos_03.mp3';
import warriorOverlap from '../assets/sounds/guerreiro_overlap.mp3';
import archerOverlap from '../assets/sounds/arqueira_overlap.mp3';
import mageOverlap from '../assets/sounds/mago_overlap.mp3';
import selectRightAnswer1 from '../assets/sounds/resposta_certa_01.mp3';
import selectRightAnswer2 from '../assets/sounds/resposta_certa_02.mp3';
import selectWrongAnswer from '../assets/sounds/resposta_errada.mp3';
import roleta from '../assets/sounds/roleta.mp3';
import upgradeItem1 from '../assets/sounds/aprimorar_item_01.mp3';
import upgradeItem2 from '../assets/sounds/aprimorar_item_02.mp3';
import equipItem1 from '../assets/sounds/equipar_item_01.mp3';
import equipItem2 from '../assets/sounds/equipar_item_02.mp3';
import equipItem3 from '../assets/sounds/equipar_item_03.mp3';
import dropCard1 from '../assets/sounds/carta_drop_01.mp3';
import dropCard2 from '../assets/sounds/carta_drop_02.mp3';
import dropCard3 from '../assets/sounds/carta_drop_03.mp3';
import selectCard1 from '../assets/sounds/carta_seleciona_01.mp3';
import selectCard2 from '../assets/sounds/carta_seleciona_02.mp3';
import selectCard3 from '../assets/sounds/carta_seleciona_03.mp3';
import levelUp from '../assets/sounds/level_up.mp3';
import archerIdentity from '../assets/sounds/archer_identity.mp3';
import warriorIdentity from '../assets/sounds/warrior_identity.mp3';
import mageIdentity from '../assets/sounds/mage_identity.mp3';

import mundoFacensMetroWav from '../assets/sounds/mundo_facens_metro.wav';

export const soundEnvironment = {
  genericButtons: [
    soundButton1,
    soundButton2,
    soundButton3,
    soundButton4,
    soundButton5,
    soundButton6,
  ],
  sidebarButtons: [
    buttonSidebar01,
    buttonSidebar02,
    buttonSidebar03,
    buttonSidebar04,
  ],
  modals: [
    sound1,
    sound2,
    sound3,
  ],
  overlap: {
    mage: mageOverlap,
    warrior: warriorOverlap,
    archer: archerOverlap,
  },
  backgroundLoop: mundoFacensMetroWav,
  item: {
    upgrade: [
      upgradeItem1,
      upgradeItem2,
    ],
    equip: [
      equipItem1,
      equipItem2,
      equipItem3,
    ],
  },
  selectRightAnswer: [
    selectRightAnswer1,
    selectRightAnswer2,
  ],
  selectWrongAnswer,
  roleta,
  levelUp: [
    levelUp,
  ],
  dropCard: [
    dropCard1,
    dropCard2,
    dropCard3,
  ],
  selectCard: [
    selectCard1,
    selectCard2,
    selectCard3,
  ],
  archerIdentity,
  warriorIdentity,
  mageIdentity,
};
