import create, { UseStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { getStoreName } from '../utils/functions';
import { UsePWAStore } from './models';

function getCurrentVersion(): Promise<{ version: number }> {
  return fetch('/version.json').then(r => r.json());
}

export function createUsePWAStore(): UseStore<UsePWAStore> {
  const name = getStoreName('usePWAStore');

  return create(
    devtools((set, get) => ({
        isAvailable: false,
        hasUpdateAvailable: false,
        onWorkerSuccess: serviceWorker => {
          set({ serviceWorker, isAvailable: true });

          
          const installedVersion = localStorage.getItem('version');

          if (!installedVersion) {
            // No version set!
            getCurrentVersion().then(r => localStorage.setItem('version', r.version.toString()));
          }
        },
        onWorkerUpdate: serviceWorker => {
          set({ hasUpdateAvailable: true, serviceWorker });

          getCurrentVersion().then(r => {
            const installedVersion = localStorage.getItem('version');
            const latestVersion = r.version.toString();

            if (installedVersion !== latestVersion) {
              // Force update
              localStorage.setItem('version', latestVersion);
              get().updatePWA();
            }
          });
        },
        updatePWA: () => {
          const serviceWorker = get().serviceWorker;

          // We send the SKIP_WAITING message to tell the Service Worker
          // to update its cache and flush the old one
          (serviceWorker?.waiting || serviceWorker?.active)?.postMessage({ type: 'SKIP_WAITING' });

          set({ hasUpdateAvailable: false });

          window.location.reload();
        },
        ignoreUpdateAvailable: () => set({ hasUpdateAvailable: false }),
      }),
      name,
    ),
  );
}
