import useSoundStore from '../../store/useSound';
import { useEffect } from 'react';
import { HookOptions, ReturnedValue } from '../useSoundNative/types';
import useSoundNative from '../useSoundNative';

function useSound(url: string | string[], options?: HookOptions): ReturnedValue {
  const volume = useSoundStore(state => state.volume);
  const soundEnabled = useSoundStore(state => state.volume > 0);

  const [play, { sound, stop, pause, isPlaying, duration }] = useSoundNative(url, {
    soundEnabled,
    ...options,
    volume: (options?.volume ?? 1) * volume,
  });

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  return [play, { sound, stop, pause, isPlaying, duration }];
}

export default useSound;
