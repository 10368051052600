import { ReactElement, useEffect } from 'react';
import usePWAStore from '../../store/usePWA';
import Modal from 'antd/lib/modal/Modal';

import * as S from './styles';

function SCServiceWorkerManager(): ReactElement {
  const serviceWorker = usePWAStore(state => state.serviceWorker);
  const hasUpdateAvailable = usePWAStore(state => state.hasUpdateAvailable);
  const updatePWA = usePWAStore(state => state.updatePWA);

  useEffect(() => {
    // We setup an event listener to automatically reload the page
    // after the Service Worker has been updated, this will trigger
    // on all the open tabs of our application, so that we don't leave
    // any tab in an incosistent state
    serviceWorker?.addEventListener('statechange', event => {
      if (event.target.state === 'activated') {
        window.location.reload();
      }
    });
  }, [serviceWorker]);

  return (
    <Modal visible={hasUpdateAvailable}
           modalRender={() => (
             <S.ModalFrame headerType="text" title="Alerta!">
               <S.Title>Há uma nova atualização!</S.Title>
               <S.Description>Por favor, clique no botão abaixo para que possa continuar utilizando o site.</S.Description>

               <S.ButtonContainer>
                 <S.Button shape="square" primary={true} onClick={updatePWA}>Atualizar!</S.Button>
               </S.ButtonContainer>
             </S.ModalFrame>
           )}
    />
  );
}

export default SCServiceWorkerManager;
